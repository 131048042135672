import React from 'react';
import Header from '../../Components/Header/Header';
import CarouselComponent from '../../Components/CarouselComponent/CarouselComponent';
import Scheduling from '../../Components/Scheduling/Scheduling';
import About from '../../Components/About/About';
import Footer from '../../Components/Footer/Footer';
import './HomePage.css'; // Importe o CSS para estilos adicionais


const HomePage = () => {

    return (
        <div>
            <section id="home">
                <Header />         
                <CarouselComponent />
            </section>

            <section id="about">
                <About />
            </section>


            <section id="scheduling">
                <Scheduling />
            </section>

            <section id="footer">
                <Footer />
            </section>
        </div>
    );
};

export default HomePage;
