import React, { useState } from 'react';
import { Carousel, Button } from 'react-bootstrap';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Fundo1 from '../../assets/imagens/carrosel1.jpeg';
import Fundo2 from '../../assets/imagens/carrosel2.jpeg';
import './CarouselComponent.css';

const CarouselComponent = () => {
    const [showPopup, setShowPopup] = useState(false);

    const handleOpenPopup = () => setShowPopup(true);
    const handleClosePopup = () => setShowPopup(false);

    const handleUnitClick = (whatsappLink) => {
        window.open(whatsappLink, '_blank', 'noopener,noreferrer');
    };

    return (
        <Carousel fade className="hero-carousel">
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Fundo1}
                    alt="Primeira imagem"
                />
                <Carousel.Caption>
                    <div className="d-flex justify-content-center">
                        <Button
                            variant="warning"
                            className="cta-button"
                            onClick={handleOpenPopup}
                            aria-label="Agendar agora na primeira imagem"
                        >
                            Agendar agora
                        </Button>
                    </div>

                    {showPopup && (
                        <div className="popup-overlay">
                            <div className="popup-content">
                                <span className="close-icon" onClick={handleClosePopup}>×</span>
                                <h3>Selecione a unidade</h3>
                                <ul className="unit-list">
                                    <li onClick={() => handleUnitClick('https://api.whatsapp.com/send/?phone=5563992384372&text&type=phone_number&app_absent=0')}>
                                        <FaMapMarkerAlt className="location-icon" /> Av. José de Brito
                                    </li>
                                    <li onClick={() => handleUnitClick('https://api.whatsapp.com/send/?phone=5563991088543&text&type=phone_number&app_absent=0')}>
                                        <FaMapMarkerAlt className="location-icon" /> Av. Marginal Neblina
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Fundo2}
                    alt="Segunda imagem"
                />
                <Carousel.Caption>
                    <div className="d-flex justify-content-center">
                        <Button
                            variant="warning"
                            className="cta-button"
                            onClick={handleOpenPopup}
                            aria-label="Agendar agora na segunda imagem"
                        >
                            Agendar agora
                        </Button>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
};

export default CarouselComponent;
